var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormulateInput',{attrs:{"name":"p_scores","type":"group","value":_vm.pScores,"repeatable":true,"add-label":"+ P-score toevoegen","remove-position":"after","group-repeatable-class":['tw-my-2 tw-flex tw-flex-row tw-gap-2 tw-items-end tw-w-full'],"outer-class":"tw-my-0"},scopedSlots:_vm._u([{key:"addmore",fn:function(ref){
var addMore = ref.addMore;
return [_c('button',{staticClass:"\n          tw-mt-1 tw-px-2 tw-py-0.5 tw-border\n          tw-text-success tw-border-success tw-rounded-md tw-bg-white\n          hover:tw-bg-success hover:tw-text-white\n          disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed\n        ",attrs:{"type":"button","title":"P-score toevoegen"},on:{"click":addMore}},[_c('i',{staticClass:"far fa-plus"}),_vm._v(" P-score ")])]}},{key:"remove",fn:function(ref){
var removeItem = ref.removeItem;
return [_c('button',{staticClass:"tw-h-10 tw-text-sm tw-rounded tw-text-danger",attrs:{"type":"button","title":"Verwijderen"},on:{"click":removeItem}},[_c('i',{staticClass:"far fa-trash"})])]}}])},[_c('div',{staticClass:"tw-flex-grow tw-flex tw-flex-row tw-gap-2"},[_c('FormulateInput',{attrs:{"type":"select","name":"value","label":"P-score","options":['A', 'B', 'C', 'D'],"validation":"required","outer-class":"tw-my-0 tw-flex-shrink-0"}}),_c('FormulateInput',{attrs:{"type":"text","name":"plot_number","label":"Perceelnummer","placeholder":"Perceelnummer","outer-class":"tw-my-0 tw-flex-grow"}})],1)]),_c('FormulateInput',{attrs:{"name":"g_scores","type":"group","value":_vm.gScores,"repeatable":true,"add-label":"+ G-score toevoegen","remove-position":"after","group-repeatable-class":['tw-my-2 tw-flex tw-flex-row tw-gap-2 tw-items-end'],"outer-class":"tw-mt-4 tw-my-0"},scopedSlots:_vm._u([{key:"addmore",fn:function(ref){
var addMore = ref.addMore;
return [_c('button',{staticClass:"\n          tw-mt-1 tw-px-2 tw-py-0.5 tw-border\n          tw-text-success tw-border-success tw-rounded-md tw-bg-white\n          hover:tw-bg-success hover:tw-text-white\n          disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed\n        ",attrs:{"type":"button","title":"G-score toevoegen"},on:{"click":addMore}},[_c('i',{staticClass:"far fa-plus"}),_vm._v(" G-score ")])]}},{key:"remove",fn:function(ref){
var removeItem = ref.removeItem;
return [_c('button',{staticClass:"tw-h-10 tw-text-sm tw-rounded tw-text-danger",attrs:{"type":"button","title":"Verwijderen"},on:{"click":removeItem}},[_c('i',{staticClass:"far fa-trash"})])]}}])},[_c('div',{staticClass:"tw-flex-grow tw-flex tw-flex-row tw-gap-2"},[_c('FormulateInput',{attrs:{"type":"select","name":"value","label":"G-score","options":['A', 'B', 'C', 'D'],"validation":"required","outer-class":"tw-my-0 tw-flex-shrink-0"}}),_c('FormulateInput',{attrs:{"type":"text","name":"plot_number","label":"Gebouw ID","placeholder":"Gebouw ID","outer-class":"tw-my-0 tw-flex-grow"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }