<template>
  <div>
    <FormulateInput
      name="p_scores"
      type="group"
      :value="pScores"
      :repeatable="true"
      add-label="+ P-score toevoegen"
      remove-position="after"
      :group-repeatable-class="['tw-my-2 tw-flex tw-flex-row tw-gap-2 tw-items-end tw-w-full']"
      outer-class="tw-my-0"
    >
      <template #addmore="{ addMore }">
        <button
          type="button"
          title="P-score toevoegen"
          class="
            tw-mt-1 tw-px-2 tw-py-0.5 tw-border
            tw-text-success tw-border-success tw-rounded-md tw-bg-white
            hover:tw-bg-success hover:tw-text-white
            disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed
          "
          @click="addMore"
        >
          <i class="far fa-plus" /> P-score
        </button>
      </template>
      <template #remove="{ removeItem }">
        <button
          type="button"
          title="Verwijderen"
          class="tw-h-10 tw-text-sm tw-rounded tw-text-danger"
          @click="removeItem"
        >
          <i class="far fa-trash" />
        </button>
      </template>
      <div class="tw-flex-grow tw-flex tw-flex-row tw-gap-2">
        <FormulateInput
          type="select"
          name="value"
          label="P-score"
          :options="['A', 'B', 'C', 'D']"
          validation="required"
          outer-class="tw-my-0 tw-flex-shrink-0"
        />
        <FormulateInput
          type="text"
          name="plot_number"
          label="Perceelnummer"
          placeholder="Perceelnummer"
          outer-class="tw-my-0 tw-flex-grow"
        />
      </div>
    </FormulateInput>
    <FormulateInput
      name="g_scores"
      type="group"
      :value="gScores"
      :repeatable="true"
      add-label="+ G-score toevoegen"
      remove-position="after"
      :group-repeatable-class="['tw-my-2 tw-flex tw-flex-row tw-gap-2 tw-items-end']"
      outer-class="tw-mt-4 tw-my-0"
    >
      <template #addmore="{ addMore }">
        <button
          type="button"
          title="G-score toevoegen"
          class="
            tw-mt-1 tw-px-2 tw-py-0.5 tw-border
            tw-text-success tw-border-success tw-rounded-md tw-bg-white
            hover:tw-bg-success hover:tw-text-white
            disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed
          "
          @click="addMore"
        >
          <i class="far fa-plus" /> G-score
        </button>
      </template>
      <template #remove="{ removeItem }">
        <button
          type="button"
          title="Verwijderen"
          class="tw-h-10 tw-text-sm tw-rounded tw-text-danger"
          @click="removeItem"
        >
          <i class="far fa-trash" />
        </button>
      </template>
      <div class="tw-flex-grow tw-flex tw-flex-row tw-gap-2">
        <FormulateInput
          type="select"
          name="value"
          label="G-score"
          :options="['A', 'B', 'C', 'D']"
          validation="required"
          outer-class="tw-my-0 tw-flex-shrink-0"
        />
        <FormulateInput
          type="text"
          name="plot_number"
          label="Gebouw ID"
          placeholder="Gebouw ID"
          outer-class="tw-my-0 tw-flex-grow"
        />
      </div>
    </FormulateInput>
  </div>
</template>

<script>
export default {
  name: 'PortfolioFloodingScores',
  props: {
    pScores: {
      type: Array
    },
    gScores: {
      type: Array
    }
  }
}
</script>
